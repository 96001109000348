/* eslint-disable camelcase */
export const DATE_FORMAT_YYYYMMdd = 'YYYYMMdd';
export const DATE_FORMAT_YYYY_MM_dd = 'YYYY-MM-dd';
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export const DATE_FORMAT_YYYY_MM_dd_Combined = 'YYYYMMddHHmm';
export const DATE_FORMAT_YYYY_MM_dd_HH_mm = 'YYYY-MM-dd HH:mm';
export const DATE_FORMAT_DD_MM_YYYY = 'DD-MM-YYYY';
export const DATE_FORMAT_DD_MM_YYYY_WITH_DOT = 'DD.MM.YYYY';
export const DATE_FORMAT_mm_dd_YYYY_WITH_SLASH = 'MM/dd/YYYY';
export const DATE_FORMAT_m_d_YYYY_WITH_SLASH = 'M/d/YYYY';
export const DATE_FORMAT_DD_MM_YYYY_WITH_SLASH = 'DD/MM/YYYY';
export const DATE_FORMAT_dd_MMM = 'ddMMM';
export const DATE_FORMAT_dd_MM_yy = 'ddMMyy';
export const DATE_FORMAT_dd_MMM_YYYY = 'ddMMMYYYY';
export const DATE_FORMAT_DD_MM_YYYY_COMBINED = 'DDMMYYYY';
export const DATE_FORMAT_DD_MM_YYYY_HH_mm_ss = "YYYY-MM-dd'T'HH:mm:ss";
export const DATE_FORMAT_DD_MM_YYYY_HH_mm_ss_sz = "YYYY-MM-dd'T'HH:mm:ss.SSS";
export const DATE_FORMAT_DD_MM_YYYY_HH_mm = 'dd-MM-YYYY HH:mm';
export const DATE_FORMAT_HH_mm = 'HH:mm';
export const DATE_FORMAT_YYYY_MM_DDTHH_mm_ssZ = "YYYY-MM-DD'T'HH:mm:ssZ";
export const DATE_FORMAT_MMM_DD_YYYY_WITH_COMMA = 'MMM DD, YYYY';
export const DATE_FORMAT_DD_MMM_YYYY_WITH_SPACE = 'DD MMM YYYY';
export const DATE_FORMAT_DD_MMM_WITH_SPACE = 'DD MMM';
export const DATE_FORMAT_MM_DD_WITH_SLASH = 'MM/DD';
export const DATE_FORMAT_dd_MMM_WITH_SPACE = 'DD MMM';
export const DATE_FORMAT_YYYY_MM_WITH_DASH = 'YYYY-MM';
export const DATE_FORMAT_D_MMM = 'D MMM';
export const DATE_FORMAT_D_MMM_YEAR = 'D MMM YYYY';
export const DATE_FORMAT_MM_DD_YYYY_HH_MM_SS = 'MM/DD/YYYY HH:mm:ss';
export const DATE_FORMAT_MM_DD_YYYY_WITH_SLASH = 'MM/DD/YYYY';
export const DATE_FORMAT_MM_DD_YYYY = 'MM-DD-YYYY';
export const DATE_FORMAT_MMM_D = 'MMM D';
export const DATE_FORMAT_MMM_D_YEAR = 'MMM D YYYY';
export const DATE_FORMAT_MM_YYYY = 'MM-YYYY';
export const DATE_FORMAT_MMM_DD_WITH_SPACE = 'MMM DD ';
export const DAY_TIME_DD_HH_MM = 'ddd h:mm A';
export const TIME_FORMAT_HH_MM_A = 'hh:mm A';
export const PROJECT_HISTORY_DATE_FORMATS = [
  'YYYY-MM-DDTHH:mm:ss',
  'ddd, DD MMM YYYY h:mm:ss A Z'
];
export const DATE_FORMAT_DD_MM_YY_WITH_SLASH = 'MM/DD/YY';
export const DATE_FORMAT_MM_DD_YY = 'MM-DD-YY';
